import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Button, CircularProgress } from "@mui/material";
import { MissingDocumentsAlert, ShiftBookingWarningDialog } from "@src/app/dayView/Alerts";
import { ShiftBookingConflictingInviteDialog } from "@src/app/dayView/Alerts/ShiftBookingConflictingInviteDialog";
import { HcfProfileSource } from "@src/app/dayView/facilityDetails/model";
import { ShiftItemCategory } from "@src/app/dayView/model";
import { useIsBreakPolicyAcknowledgementRequired } from "@src/app/mandatoryBreakPolicy/hooks/useIsBreakPolicyAcknowledgementRequired";
import { GeneralLocationPrimerModal } from "@src/app/openShifts/urgentShifts/generalLocationPrimerModal/generalLocationPrimerModal";
import { requestFullLocationAccess } from "@src/app/openShifts/urgentShifts/utils";
import { AppV2AccountRoutes } from "@src/app/routing/constant/appV2AccountRoutes";
import { generateDocumentDetailsPath } from "@src/appV2/Accounts/DocumentDetails/generateDocumentDetailsPath";
import { DocumentRequirementType } from "@src/appV2/Accounts/Documents";
import { useGetMissingRequirementsForDate } from "@src/appV2/Accounts/Documents/api/useGetMissingRequirementsForDate";
import { getQualificationForDocumentCheck } from "@src/appV2/Accounts/Documents/helpers";
import {
  MissingDocumentsAlertSource,
  logDocumentsDropoffFlowClickedViewDocuments,
  logDocumentsDropoffFlowViewedMissingDocumentsAlert,
} from "@src/appV2/Accounts/Documents/logUtils";
import { isCapacitorPlatform, useToast } from "@src/appV2/lib";
import { APP_V2_APP_EVENTS, APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import {
  useDeviceGeoLocationIfAllowed,
  useIsDeviceLocationPermissionGranted,
} from "@src/appV2/Location/deviceLocation/geoLocation";
import { useAcceptNegotiation } from "@src/appV2/Negotiations/api/useAcceptNegotiation";
import {
  BookabilityAttributes,
  BookabilityDecision,
  BookabilityUnmetCriteria,
} from "@src/appV2/OpenShifts/ShiftAction";
import {
  ShiftSearchMode,
  useClaimShift,
} from "@src/appV2/OpenShifts/ShiftAction/api/useClaimShift";
import { useMarkInterestShift } from "@src/appV2/OpenShifts/ShiftAction/api/useMarkInterestShift";
import {
  BookabilityNegotiationMetadata,
  ClaimShiftResponse,
} from "@src/appV2/OpenShifts/ShiftAction/types";
import { useCheckPolicyAcknowledgement } from "@src/appV2/Shifts/MandatoryBreakPolicy/api/useCheckPolicyAcknowledgement";
import { usePostPolicyAcknowledgement } from "@src/appV2/Shifts/MandatoryBreakPolicy/api/usePostPolicyAcknowledgement";
import { MandatoryBreakPolicyDialog } from "@src/appV2/Shifts/MandatoryBreakPolicy/MandatoryBreakPolicyDialog";
import { NoteAcknowledgementAction } from "@src/appV2/Shifts/MandatoryBreakPolicy/types";
import { useWorkerPendingShiftInvites } from "@src/appV2/Shifts/ShiftInvites/api/useWorkerPendingShiftInvites";
import { ShiftInvite } from "@src/appV2/Shifts/ShiftInvites/types";
import { getConflictingShiftInvitesWithShift } from "@src/appV2/Shifts/ShiftInvites/utils";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useGetWorkplaceQuizMutation } from "@src/appV2/WorkplaceQuiz/api/useGetWorkplaceQuizMutation/useGetWorkplaceQuizMutation";
import { usePostQuizView } from "@src/appV2/WorkplaceQuiz/api/usePostQuizView/usePostQuizView";
import { WorkplaceQuizRequirementDialog } from "@src/appV2/WorkplaceQuiz/Dialogs/WorkplaceQuizRequirementDialog";
import { openQuizDialogModal } from "@src/appV2/WorkplaceQuiz/utils/openQuizDialogModal";
import { WorkplaceRulesWithQuiz } from "@src/appV2/WorkplaceQuiz/WorkplaceRulesWithQuiz/WorkplaceRulesWithQuiz";
import { HCF_PROFILE_SOURCE } from "@src/constants";
import { type Shift } from "@src/lib/interface/src";
import { formatDollarsAsUsd } from "@src/utils/currency";
import { addDays, parseISO } from "date-fns";
import { useRef, useState } from "react";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

export interface BookShiftButtonProps {
  shiftBookability: BookabilityAttributes;
  shift: Shift;
  isUrgentShift: boolean;
  buttonStyles?: React.CSSProperties;
  onBookShift: (shift: ClaimShiftResponse) => void;
  onShiftMarkInterest: () => void;
  agentId?: string;
  searchMode: ShiftSearchMode;
  hcfProfileSource?: HcfProfileSource;
  negotiationMetadata?: BookabilityNegotiationMetadata;
}

export function BookShiftButton(props: BookShiftButtonProps) {
  const {
    shiftBookability,
    shift,
    isUrgentShift,
    buttonStyles = {},
    onBookShift,
    onShiftMarkInterest,
    agentId,
    searchMode,
    hcfProfileSource,
    negotiationMetadata,
  } = props;

  const location = useLocation();

  const {
    modalIsOpen: missingDocumentsAlertIsOpen,
    openModal: openMissingDocumentsAlert,
    closeModal: closeMissingDocumentsAlert,
  } = useModalState();

  const breakPolicyAcknowledgementModalState = useModalState();
  const quizRequirementDialogModal = useModalState();
  const quizDialogModal = useModalState();

  const worker = useDefinedWorker();

  const workerPreferenceQualification = worker?.preference?.qualification;

  const documentsDropOffFlowId = useRef<string | undefined>(undefined);

  const {
    modalIsOpen: bookingWarningModalIsOpen,
    openModal: openBookingWarningModal,
    closeModal: closeBookingWarningModal,
  } = useModalState();

  const {
    modalIsOpen: generalLocationPrimerModalIsOpen,
    openModal: openGeneralLocationPrimerModal,
    closeModal: closeGeneralLocationPrimerModal,
  } = useModalState();

  const { showErrorToast, showSuccessToast } = useToast();

  const { mutateAsync: claimShift, isLoading: isClaimShiftLoading } = useClaimShift();
  const { mutateAsync: markInterestShift, isLoading: isMarkInterestShiftLoading } =
    useMarkInterestShift();
  const { mutateAsync: acceptNegotiation, isLoading: isAcceptNegotiationLoading } =
    useAcceptNegotiation();

  const history = useHistory();

  const { data: isDeviceLocationPermissionGranted } = useIsDeviceLocationPermissionGranted();
  const geolocationIsAvailable = isDeviceLocationPermissionGranted || !isCapacitorPlatform();

  const { data: deviceGeolocation } = useDeviceGeoLocationIfAllowed({
    enabled: Boolean(geolocationIsAvailable),
  });

  const {
    mutateAsync: getMissingRequirementsForDate,
    data: missingRequirementsForDate,
    isLoading: isGetMissingRequirementsLoading,
    isSuccess: isGetMissingRequirementsSuccess,
  } = useGetMissingRequirementsForDate();

  const { mutate: postQuizView } = usePostQuizView();

  const blockedDueToQuizRequirementMetadata =
    shiftBookability?.decision === BookabilityDecision.BLOCKED &&
    shiftBookability?.unmetCriteriaWithMetadata?.[0].unmetCriteria ===
      BookabilityUnmetCriteria.WORKER_HAS_NOT_PASSED_QUIZ_FOR_WORKPLACE_RULES
      ? shiftBookability?.unmetCriteriaWithMetadata?.[0].metadata
      : undefined;
  /**
   * We do not want to discourage workers from booking shifts due to quiz,
   * hence we treat a shift as instant bookable even though bookability is blocked becuase of quiz
   * */
  const isInstantBookingShift =
    shiftBookability.decision === BookabilityDecision.ALLOWED ||
    shiftBookability.decision === BookabilityDecision.UNDETERMINED ||
    isDefined(blockedDueToQuizRequirementMetadata);

  const missingDocuments = isGetMissingRequirementsSuccess
    ? missingRequirementsForDate.data.hcpMissingRequirementsForDate.map((params) => ({
        reqId: params.reqId,
        name: params.name,
        visibleToHCP: params.visibleToHCP,
      }))
    : [];

  const missingDocumentsSet = new Set(missingDocuments);

  const {
    isRequired: isBreakPolicyAcknowledgementRequired,
    noteId: breakPolicyNoteId,
    noteContent: breakPolicyNoteContent,
  } = useIsBreakPolicyAcknowledgementRequired({
    shift,
    facilityNotes: shift.facility?.facilityNotes ?? [],
  });

  const {
    mutateAsync: getWorkplaceQuiz,
    isLoading: isGetWorkplaceQuizLoading,
    isSuccess: isGetWorkplaceQuizSuccess,
    data: quizData,
  } = useGetWorkplaceQuizMutation();

  const quiz = isGetWorkplaceQuizSuccess ? quizData.data?.[0] : undefined;
  const facilityNotes =
    isGetWorkplaceQuizSuccess && isDefined(quizData.included)
      ? quizData.included?.map((noteResponse) => ({
          isVisibleToWorker: noteResponse.attributes.isVisibleToWorker,
          categoryTextVisibleToWorker: noteResponse.attributes.categoryTextVisibleToWorker,
          noteId: noteResponse.attributes.noteId,
          note: noteResponse.attributes.note,
          identifier: noteResponse.attributes.identifier,
        }))
      : [];

  const {
    mutateAsync: getBreakPolicyAcknowledgements,
    isLoading: isBreakPolicyAcknowledgementsLoading,
  } = useCheckPolicyAcknowledgement();
  const { mutate: postBreakPolicyAcknowledgement } = usePostPolicyAcknowledgement();

  const policyAcknowledgementEventPayload = {
    action: NoteAcknowledgementAction.BOOK_SHIFT,
    noteId: breakPolicyNoteId,
    shiftId: shift._id,
    facilityId: shift.facility?.userId,
    agentId,
  };

  const isBookButtonLoading =
    isGetMissingRequirementsLoading ||
    isClaimShiftLoading ||
    isMarkInterestShiftLoading ||
    isBreakPolicyAcknowledgementsLoading ||
    isGetWorkplaceQuizLoading ||
    isAcceptNegotiationLoading;

  const { data: shiftInvitesData, isSuccess: isPendingShiftInvitesSuccess } =
    useWorkerPendingShiftInvites({
      filterParams: isDefined(shift.start)
        ? {
            shiftDetails: {
              start: {
                gte: addDays(parseISO(shift.start), -1).toISOString(),
                lte: shift.end,
              },
            },
          }
        : {},
    });

  const [conflictingInvites, setConflictingInvites] = useState<ShiftInvite[]>([]);
  const shiftBookingConflictingInviteDialogState = useModalState();

  async function bookShift() {
    const shiftId = shift.shiftId ?? shift._id;
    const offerId = shift.offer?.id;

    const bookedFrom =
      hcfProfileSource === HCF_PROFILE_SOURCE.PERMANENT_PLACEMENTS_WORK_TRIALS
        ? HCF_PROFILE_SOURCE.PERMANENT_PLACEMENTS_WORK_TRIALS
        : undefined;

    try {
      if (isDefined(negotiationMetadata)) {
        const { bookedShift } = await acceptNegotiation({
          negotiationId: negotiationMetadata.negotiationId,
        });
        showSuccessToast("You have booked this shift.");

        onBookShift(bookedShift);
      } else {
        const bookedShift = await claimShift({
          shiftId: shiftId ?? "",
          offerId: offerId ?? "",
          searchMode,
          latitude: deviceGeolocation?.geoLocation?.latitude,
          longitude: deviceGeolocation?.geoLocation?.longitude,
          bookedFrom,
        });
        showSuccessToast("You have booked this shift.");

        if (hcfProfileSource === HCF_PROFILE_SOURCE.PERMANENT_PLACEMENTS_WORK_TRIALS) {
          logEvent(APP_V2_APP_EVENTS.PLACEMENT_WORK_TRIAL_SHIFT_BOOKED, {
            workerId: worker.userId,
            userEmail: worker.email,
            workerAlreadyWorked: worker.alreadyWorked,
            workplaceId: shift.facility?.userId,
            shiftId,
            source: "instant_book",
          });
        }

        onBookShift(bookedShift);
      }

      logEvent(APP_V2_USER_EVENTS.INSTANT_BOOKED_SHIFT, {
        shiftId,
        facilityId: shift?.facilityId,
        facilityType: shift?.facility?.type,
        shiftName: shift.name,
        negotiationMetadata,
      });
    } catch (error) {
      const errorMessage =
        error.response?.data?.displayableMessage ||
        "Something went wrong while booking this shift. Please try again.";
      showErrorToast(errorMessage);
    }
  }

  function getBookShiftButtonLabel() {
    if (isDefined(negotiationMetadata)) {
      return `Book at ${formatDollarsAsUsd(negotiationMetadata.bookRate, {
        minimumFractionDigits: 0,
      })}/hr`;
    }

    if (isInstantBookingShift) {
      return "Book This Shift";
    }
    return "Request This Shift";
  }

  return (
    <>
      <Button
        variant="contained"
        fullWidth
        size="small"
        sx={{ backgroundColor: "primary", ...buttonStyles }}
        disabled={isBookButtonLoading}
        onClick={async () => {
          logEvent(APP_V2_USER_EVENTS.TAPPED_BUTTON_FOR_ALLOWED_BOOKABILITY_STATUS, {
            decision: shiftBookability.decision,
            shiftId: shift._id,
            isInstantBookingShift,
            instantPay: shift?.isInstantPay,
            searchMode,
          });

          if (!geolocationIsAvailable) {
            openGeneralLocationPrimerModal();
            return;
          }

          try {
            const missingRequirementsForDate = await getMissingRequirementsForDate({
              hcfId: shift.facilityId ?? "",
              date: shift.end ?? "",
              qualification: getQualificationForDocumentCheck({
                shiftQualificationRequirement: shift.agentReq ?? "",
                selectedWorkerQualification: workerPreferenceQualification ?? "",
              }),
            });

            if (missingRequirementsForDate?.data.hcpMissingRequirementsForDate.length > 0) {
              openMissingDocumentsAlert();
              documentsDropOffFlowId.current = uuidv4();
              logDocumentsDropoffFlowViewedMissingDocumentsAlert({
                missingDocuments: new Set(
                  missingRequirementsForDate?.data.hcpMissingRequirementsForDate
                ),
                shiftId: shift._id ?? "",
                facilityId: shift.facilityId ?? "",
                documentsDropOffFlowId: documentsDropOffFlowId.current,
                source: MissingDocumentsAlertSource.BOOK_SHIFT,
              });
              return;
            }
          } catch {
            showErrorToast("Something went wrong while checking your documents. Please try again.");
          }

          if (isBreakPolicyAcknowledgementRequired) {
            const existingAcknowledgements = await getBreakPolicyAcknowledgements({
              noteId: breakPolicyNoteId,
              policyAcknowledgementAction: NoteAcknowledgementAction.BOOK_SHIFT,
            });

            if (existingAcknowledgements?.data.length === 0) {
              breakPolicyAcknowledgementModalState.openModal();
              logEvent(
                APP_V2_USER_EVENTS.MANDATORY_BREAK_POLICY_VIEWED,
                policyAcknowledgementEventPayload
              );
              return;
            }
          }

          if (
            isDefined(blockedDueToQuizRequirementMetadata) &&
            isDefined(shift.facilityId) &&
            !isDefined(negotiationMetadata?.negotiationId)
          ) {
            try {
              // The quiz data is not directly used in this scope
              // but rather used from the mutation result
              openQuizDialogModal({
                workplaceId: shift.facilityId,
                shiftId: shift._id ?? "",
                source: "booking",
                postQuizView,
                getWorkplaceQuiz,
                quizRequirementDialogModal,
              });
              return;
            } catch {
              showErrorToast("Something went wrong while fetching quiz. Please try again.");
              return;
            }
          }

          if (isPendingShiftInvitesSuccess && isDefined(shift.start) && isDefined(shift.end)) {
            const conflictingInviteResults = getConflictingShiftInvitesWithShift(
              shiftInvitesData.data,
              {
                start: shift.start,
                end: shift.end,
                facilityId: shift.facilityId ?? shift.facility?.userId,
              }
            );

            if (conflictingInviteResults.length > 0) {
              setConflictingInvites(conflictingInviteResults);
              shiftBookingConflictingInviteDialogState.openModal();
              return;
            }
          }

          openBookingWarningModal();
        }}
      >
        <b>{getBookShiftButtonLabel()}</b>
        {isBookButtonLoading && <CircularProgress color="inherit" size="1.2rem" sx={{ mx: 1 }} />}
      </Button>

      {isDefined(shift.facility) && isDefined(shift.facility.name) && (
        <WorkplaceQuizRequirementDialog
          workplaceName={shift.facility.name}
          workerQuizResultStatus={blockedDueToQuizRequirementMetadata?.workerQuizResultStatus}
          modalState={quizRequirementDialogModal}
          onContinue={() => {
            logEvent(APP_V2_USER_EVENTS.TAPPED_BUTTON_FOR_CONTINUE_ON_QUIZ_REQUIREMENT_DIALOG, {
              shiftId: shift?._id,
              workplaceId: shift?.facilityId,
            });
            quizDialogModal.openModal();
            logEvent(APP_V2_USER_EVENTS.VIEWED_WORKPLACE_RULES_PAGE, {
              shiftId: shift?._id,
              workplaceId: shift?.facilityId,
              source: "booking",
            });
          }}
        />
      )}

      {isDefined(quiz) && isDefined(shift.facility) && isDefined(shift.facility.name) && (
        <WorkplaceRulesWithQuiz
          facilityName={shift.facility.name}
          facilityNotes={facilityNotes}
          quizRulesDialog={quizDialogModal}
          quiz={quiz}
          onQuizPassed={async () => {
            await bookShift();
          }}
          logMetadata={{
            shiftId: shift?._id,
            source: "booking",
          }}
        />
      )}

      <ShiftBookingConflictingInviteDialog
        modalState={shiftBookingConflictingInviteDialogState}
        conflictingInvites={conflictingInvites}
        openBookingWarningModal={openBookingWarningModal}
      />

      <ShiftBookingWarningDialog
        isOpen={bookingWarningModalIsOpen}
        shift={shift}
        isInstantBookingShift={isInstantBookingShift}
        isUrgentShift={isUrgentShift}
        onClose={closeBookingWarningModal}
        isLoading={isClaimShiftLoading || isAcceptNegotiationLoading || isMarkInterestShiftLoading}
        onConfirm={async () => {
          if (isInstantBookingShift) {
            await bookShift();
          } else {
            const shiftId = shift._id ?? "";

            try {
              await markInterestShift({
                shift: shiftId,
              });

              showSuccessToast("Your booking has been noted, a staffer will be in touch.");

              logEvent(APP_V2_USER_EVENTS.MARKED_INTEREST_SHIFT, {
                shiftId,
                facilityId: shift?.facilityId,
                facilityType: shift?.facility?.type,
                shiftName: shift.name,
              });
              onShiftMarkInterest();
            } catch (error) {
              const errorMessage =
                error.response?.data?.error ||
                "Something went wrong while requesting this shift. Please try again.";
              showErrorToast(errorMessage);
            }
          }
          closeBookingWarningModal();
        }}
      />

      <MissingDocumentsAlert
        expiringInFuture
        shift={shift}
        isOpen={missingDocumentsAlertIsOpen}
        missingDocuments={missingDocumentsSet}
        category={ShiftItemCategory.MISSING_CBH_DOCUMENTS_BEFORE_A_SHIFT_END}
        onConfirm={() => {
          logDocumentsDropoffFlowClickedViewDocuments({
            numberOfMissingDocuments: missingDocumentsSet.size,
            documentsDropOffFlowId: documentsDropOffFlowId.current ?? "",
            source: MissingDocumentsAlertSource.BOOK_SHIFT,
          });
          if (missingDocumentsSet.size === 1) {
            const documentDetailsPath = generateDocumentDetailsPath({
              hcfId: shift.facilityId,
              requirement: missingDocumentsSet.values().next().value?.reqId,
              requirementStatus: DocumentRequirementType.MISSING,
            });
            history.push(documentDetailsPath, {
              returnUrl: location.pathname,
              documentsDropOffFlowId: documentsDropOffFlowId.current,
            });
          } else {
            history.push(
              generatePath(AppV2AccountRoutes.HCF_DOCUMENT_BY_ID_AND_NAME, {
                hcfId: shift.facilityId,
                hcfName: shift.facility?.name,
              }),
              {
                returnUrl: location.pathname,
                documentsDropOffFlowId: documentsDropOffFlowId.current,
              }
            );
          }
        }}
        onDidDismiss={closeMissingDocumentsAlert}
      />

      {isBreakPolicyAcknowledgementRequired && isDefined(breakPolicyNoteContent) ? (
        <MandatoryBreakPolicyDialog
          modalState={breakPolicyAcknowledgementModalState}
          contentLines={breakPolicyNoteContent.split("\n")}
          acknowledgementAction={NoteAcknowledgementAction.BOOK_SHIFT}
          onClose={() => {
            logEvent(
              APP_V2_USER_EVENTS.MANDATORY_BREAK_POLICY_CANCELLED,
              policyAcknowledgementEventPayload
            );
          }}
          onContinue={async () => {
            postBreakPolicyAcknowledgement({
              policyAcknowledgementAction: NoteAcknowledgementAction.BOOK_SHIFT,
              noteId: breakPolicyNoteId ?? "",
            });
            logEvent(
              APP_V2_USER_EVENTS.MANDATORY_BREAK_POLICY_ACCEPTED,
              policyAcknowledgementEventPayload
            );
            if (isDefined(blockedDueToQuizRequirementMetadata) && isDefined(shift.facilityId)) {
              try {
                openQuizDialogModal({
                  workplaceId: shift.facilityId,
                  shiftId: shift._id ?? "",
                  source: "booking",
                  postQuizView,
                  getWorkplaceQuiz,
                  quizRequirementDialogModal,
                });
                return;
              } catch {
                showErrorToast("Something went wrong while fetching quiz. Please try again.");
                return;
              }
            } else {
              openBookingWarningModal();
            }
          }}
        />
      ) : null}

      <GeneralLocationPrimerModal
        isOpen={generalLocationPrimerModalIsOpen}
        onDidDismiss={closeGeneralLocationPrimerModal}
        onDeny={closeGeneralLocationPrimerModal}
        onAllowLocationClick={() => {
          closeGeneralLocationPrimerModal();
          void requestFullLocationAccess();
        }}
      />
    </>
  );
}
